import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import assets from "../../../utils/Assets";
import withOutsideClickNurse from "./withOutsideClickNurse";
import { useGetNurseByUserId } from "../../../hooks/useNurse";
import { useCODData } from "../../../context/CODDataContext";
import bugleTune from "../../../assets/sound/bugle_tune.ogg";

const NurseMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const location = useLocation();
  const [notificationPermission, setNotificationPermission] = useState(
    "default"
  );

  const { isTalkWaiting, isAlertWaiting, isNurseCODWaiting } = useCODData();

  const playNotificationSound = () => {
    const audio = new Audio(bugleTune);
    audio.volume = 0.5; // Adjust volume if needed
    audio.play().catch((error) => console.log("Audio playback failed:", error));
  };

  useEffect(() => {
    // Check if browser supports notifications
    if ("Notification" in window) {
      setNotificationPermission(Notification.permission);
    }
  }, []);

  useEffect(() => {
    if (isTalkWaiting && notificationPermission !== "granted") {
      // Request permission when there's a waiting talk and permission not granted
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
        if (permission === "granted") {
          // Play sound and show notification
          playNotificationSound();
          new Notification("New Talk Request", {
            body: "You have a new talk request waiting",
            icon: "https://static.meragoapp.com/images/menuLogo.png",
          });
        }
      });
    } else if (isTalkWaiting && notificationPermission === "granted") {
      // Play sound and show notification if permission already granted
      playNotificationSound();
      new Notification("New Talk Request", {
        body: "You have a new talk request waiting",
        icon: "https://static.meragoapp.com/images/menuLogo.png",
      });
    }
  }, [isTalkWaiting, notificationPermission]);

  const useAuth = () => {
    const user = localStorage.getItem("nurseID");
    return Boolean(user);
  };

  const showAlert =
    (isTalkWaiting || isAlertWaiting || isNurseCODWaiting) &&
    !location.pathname.includes("/nurse-service");

  const user = useAuth();

  const navigation = useNavigate();
  const { data: nurseData } = useGetNurseByUserId(
    localStorage.getItem("nurseID") || ""
  );

  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("nurseAuth");
    }
    localStorage.removeItem("nurseID");
    localStorage.removeItem("nurseAuth");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("nurse_refresh_token");
    navigation("/login");
    setOpen(!open);
  };

  useEffect(() => {
    if (nurseData) {
      localStorage.setItem("nurseUn", nurseData?.agentDetails?.firstname);
    }
  }, [nurseData]);

  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>
            <div className="navbar__items nav__more__left">
              {!location.pathname.includes("/nurse-consultation") && (
                <>
                  <Link
                    to="talk-nurse"
                    className={
                      location.pathname.includes("/talk-nurse")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    {isTalkWaiting && (
                      <div className={showAlert ? "red__alert" : ""}></div>
                    )}
                    Home
                  </Link>

                  <Link
                    to="nurse-dashboard"
                    className={
                      location.pathname.includes("/nurse-dashboard")
                        ? "sidebar_active"
                        : ""
                    }
                  >
                    {isNurseCODWaiting && (
                      <div className={showAlert ? "red__alert" : ""}></div>
                    )}
                    Nurse Care
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="sideBar__main" onClick={() => setOpen(!open)}>
            <p>
              {nurseData?.agentDetails?.firstname}{" "}
              {nurseData?.agentDetails?.lastname}
            </p>
            <img src={assets.downArrow} alt="merago" />
          </div>
          {open ? (
            <div className="sidebar__conatiner agent__sidebar">
              {location.pathname !== "/agent" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
});

export default withOutsideClickNurse(NurseMenu);
